interface StripeData {
  publishableKey: string
  weeklyProductId: string
}

export interface PartnerClientData {
  clientId?: string,
  clientType?: string,
  mbsEncryptionKey?: string,
  mbsAuthCheckInterval?: string
}

interface EncryptionData {
  mbsEncryptionKey? : string
}

export const FIREBASE_OPTIONS : any = isProd() ? {
  apiKey: "AIzaSyDtbT9s9ztbyef8hc18Iohk-uE9D_qdaQk",
  authDomain: "haus-test-25d93.firebaseapp.com",
  databaseURL: "https://haus-test-25d93.firebaseio.com",
  projectId: "haus-test-25d93",
  storageBucket: "haus-test-25d93.appspot.com",
  messagingSenderId: "331928952405",
  appId: "1:331928952405:web:8048394d040987aa6b3edc",
  measurementId: "G-J3CNCE2BVW"
} : {
  apiKey: "AIzaSyCByRo_ffcCkIHrFAfW13bv1be6eqeQjOU",
  authDomain: "haus-dev-a4e3d.firebaseapp.com",
  databaseURL: "https://haus-dev-a4e3d.firebaseio.com",
  projectId: "haus-dev-a4e3d",
  storageBucket: "haus-dev-a4e3d.appspot.com",
  messagingSenderId: "1092389779240",
  appId: "1:1092389779240:web:a0151ed9407695771f421d",
  measurementId: "G-3MTNZ8P36L"
}

export const MUX_DATA_KEY = isProd() ? 'f9n8aa014tfj25g846sepbegb' : 'dp06h791gtsh1nkdosldp03tr'

export const STRIPE_DATA : StripeData = isProd() ? {
  publishableKey: "pk_live_51HApkvDBwDRkDxHpm7RAf6efNFtzKYm7oXIGxpMaFB8xkCFfLLskByfSclGKi27qAH6uoprmIn7pQsY0CRIFDDt0007bvwann3",
  weeklyProductId: "price_1LNwQQDBwDRkDxHpRuGhKrHl",
} : {
  publishableKey: "pk_test_51HApkvDBwDRkDxHppdVnQuMATmBwqT53c9HEETZTEEmWuLw4PwtFYCm1ZGhA52AiTmeNhQYbkNqtIcezwABEnrLM000OJAtsBd",
  weeklyProductId: "price_1IoqcmDBwDRkDxHpiA0KyYow",
}

export const PARTNER_CLIENT_DATA: PartnerClientData = {
  clientId: process.env.GATSBY_HAUS_CLIENT_ID,
  clientType: process.env.GATSBY_HAUS_CLIENT_TYPE,
  mbsEncryptionKey: process.env.GATSBY_MBS_ENCRYPTION_KEY,
  mbsAuthCheckInterval: process.env.GATSBY_MBS_USERS_AUTH_CHECK_INTERVAL
}

function isProd() {
  return (process.env.GATSBY_NODE_ENV || process.env.NODE_ENV) === `production`
}
