export const LOGIN_ERROR_WRONG_PASSWORD = "auth/wrong-password"
export const LOGIN_ERROR_INVALID_EMAIL = "auth/invalid-email"
export const LOGIN_ERROR_USER_NOT_FOUND = "auth/user-not-found"
export const UNAUTHENTICATED = "unauthenticated"

export interface LoginDetails {
  email: string
  password: string
}

export interface LoginFormErrors {
  email?: string | null
  password?: string | null
}
