// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-features-browse-browse-tsx": () => import("./../../../src/features/browse/Browse.tsx" /* webpackChunkName: "component---src-features-browse-browse-tsx" */),
  "component---src-features-solo-solo-wrapper-tsx": () => import("./../../../src/features/solo/SoloWrapper.tsx" /* webpackChunkName: "component---src-features-solo-solo-wrapper-tsx" */),
  "component---src-features-videochat-group-workout-wrapper-js": () => import("./../../../src/features/videochat/GroupWorkoutWrapper.js" /* webpackChunkName: "component---src-features-videochat-group-workout-wrapper-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-logout-tsx": () => import("./../../../src/pages/logout.tsx" /* webpackChunkName: "component---src-pages-logout-tsx" */),
  "component---src-pages-membership-tsx": () => import("./../../../src/pages/membership.tsx" /* webpackChunkName: "component---src-pages-membership-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */)
}

